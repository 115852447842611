// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-post-layout-js": () => import("./../../../src/components/PostLayout.js" /* webpackChunkName: "component---src-components-post-layout-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-affiliate-disclosure-js": () => import("./../../../src/pages/affiliate-disclosure.js" /* webpackChunkName: "component---src-pages-affiliate-disclosure-js" */),
  "component---src-pages-drpepper-caffeine-free-js": () => import("./../../../src/pages/drpepper-caffeine-free.js" /* webpackChunkName: "component---src-pages-drpepper-caffeine-free-js" */),
  "component---src-pages-drpepper-cherry-js": () => import("./../../../src/pages/drpepper-cherry.js" /* webpackChunkName: "component---src-pages-drpepper-cherry-js" */),
  "component---src-pages-drpepper-cherry-vanilla-js": () => import("./../../../src/pages/drpepper-cherry-vanilla.js" /* webpackChunkName: "component---src-pages-drpepper-cherry-vanilla-js" */),
  "component---src-pages-drpepper-cherry-zero-js": () => import("./../../../src/pages/drpepper-cherry-zero.js" /* webpackChunkName: "component---src-pages-drpepper-cherry-zero-js" */),
  "component---src-pages-drpepper-cream-soda-js": () => import("./../../../src/pages/drpepper-cream-soda.js" /* webpackChunkName: "component---src-pages-drpepper-cream-soda-js" */),
  "component---src-pages-drpepper-cream-soda-zero-js": () => import("./../../../src/pages/drpepper-cream-soda-zero.js" /* webpackChunkName: "component---src-pages-drpepper-cream-soda-zero-js" */),
  "component---src-pages-drpepper-diet-js": () => import("./../../../src/pages/drpepper-diet.js" /* webpackChunkName: "component---src-pages-drpepper-diet-js" */),
  "component---src-pages-drpepper-js": () => import("./../../../src/pages/drpepper.js" /* webpackChunkName: "component---src-pages-drpepper-js" */),
  "component---src-pages-drpepper-zero-js": () => import("./../../../src/pages/drpepper-zero.js" /* webpackChunkName: "component---src-pages-drpepper-zero-js" */),
  "component---src-pages-get-help-js": () => import("./../../../src/pages/get-help.js" /* webpackChunkName: "component---src-pages-get-help-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-risks-js": () => import("./../../../src/pages/risks.js" /* webpackChunkName: "component---src-pages-risks-js" */),
  "component---src-pages-signs-js": () => import("./../../../src/pages/signs.js" /* webpackChunkName: "component---src-pages-signs-js" */),
  "component---src-templates-content-index-js": () => import("./../../../src/templates/content-index.js" /* webpackChunkName: "component---src-templates-content-index-js" */)
}

